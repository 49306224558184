const messages = {
  en: {
    "dj.djsandboxio": "djsandbox.io",
    "auth.welcomeback": "Welcome back, sign in with your account!",
    "auth.email": "Email",
    "auth.password": "Password",
    "auth.signup": "Sign Up",
    "auth.signupnewaccount": "Sign up with a new account!",
    "auth.signin": "Sign In",
    "auth.signinexistinguser": "Sign In - Existing User",
    "auth.logout": "Log Out",
    "auth.forgotpassword": "Forgot Password?",
    "auth.forgotpasswordthatsok": "Forgot Password? That's OK.",
    "auth.sendcodeverify":
      "We'll send a code to your email, to verify your account.",
    "auth.codesent": "Check your email for the verification code.",
    "auth.sendcode": "Send Code",
    "auth.cancelremembered": "Cancel - I remembered my password.",
    "auth.code": "Code",
    "auth.verifywithcode": "Verify With Code",
    "auth.didntgetcode": "Didn't get a code?",
    "auth.resendcode": "Resend Code",
    "auth.checkemailcode":
      "Check your email, and confirm your account with the code we sent.",
    "auth.accountdetails": "Enter your account details.",
    "auth.pleasesignin": "Please sign in to view this content",
    "account.settings": "Account Settings",
    "account.currentpassword": "Current Password",
    "account.newpassword": "New Password",
    "account.confirmnewpassword": "Confirm New Password",
    "account.confirmpassword": "Confirm Password",
    "account.changepassword": "Change Password",
    "account.setnewpassword": "Set New Password",
    "account.newemail": "New Email",
    "account.changeemail": "Change Email",
    "app.welcomeback": "Welcome back to the app!",
  },
  es: {
    "dj.djsandboxio": "##djsandbox.io",
    "auth.welcomeback": "##Welcome back, sign in with your account!",
    "auth.email": "##Email",
    "auth.password": "##Password",
    "auth.signup": "##Sign Up",
    "auth.signupnewaccount": "##Sign up with a new account!",
    "auth.signin": "##Sign In",
    "auth.signinexistinguser": "##Sign In - Existing User",
    "auth.logout": "##Log Out",
    "auth.forgotpassword": "##Forgot Password?",
    "auth.forgotpasswordthatsok": "##Forgot Password? That's OK.",
    "auth.sendcodeverify":
      "##We'll send a code to your email, to verify your account.",
    "auth.codesent": "##Check your email for the verification code.",
    "auth.sendcode": "##Send Code",
    "auth.cancelremembered": "##Cancel - I remembered my password.",
    "auth.code": "##Code",
    "auth.verifywithcode": "##Verify With Code",
    "auth.didntgetcode": "##Didn't get a code?",
    "auth.resendcode": "##Resend Code",
    "auth.checkemailcode":
      "##Check your email, and confirm your account with the code we sent.",
    "auth.accountdetails": "##Enter your account details.",
    "auth.pleasesignin": "##Please sign in to view this content",
    "account.settings": "##Account Settings",
    "account.currentpassword": "##Current Password",
    "account.newpassword": "##New Password",
    "account.confirmnewpassword": "##Confirm New Password",
    "account.confirmpassword": "##Confirm Password",
    "account.changepassword": "##Change Password",
    "account.setnewpassword": "##Set New Password",
    "account.newemail": "##New Email",
    "account.changeemail": "##Change Email",
    "app.welcomeback": "##Welcome back to the app!",
  },
};

export default messages;
