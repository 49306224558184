import { lazy } from "react";

export interface AppRoute {
  path: string;
  name: string;
  component: JSX.Element;
  public: boolean;
}

const DashboardPage = lazy(() => import("./Dashboard"));
const PlaylistPage = lazy(() => import("./Playlist"));
const ExplorePage = lazy(() => import("./Explore"));
const SignUpPage = lazy(() => import("./SignUp"));
const SignInPage = lazy(() => import("./SignIn"));
const ForgotPasswordPage = lazy(() => import("./ForgotPassword"));

export const appUrls = {
  dashboard: "/dashboard",
  playlist: "/playlist",
  explore: "/explore",
  signup: "/signup",
  signin: "/signin",
  forgotpassword: "/forgotpassword",
};

export const appRoutes: AppRoute[] = [
  {
    path: appUrls.dashboard,
    name: "Dashboard",
    component: <DashboardPage />,
    public: false,
  },
  {
    path: appUrls.playlist,
    name: "Playlist",
    component: <PlaylistPage />,
    public: false,
  },
  {
    path: appUrls.explore,
    name: "Explore",
    component: <ExplorePage />,
    public: false,
  },
  {
    path: appUrls.signup,
    name: "Sign Up",
    component: <SignUpPage />,
    public: true,
  },
  {
    path: appUrls.signin,
    name: "Sign In",
    component: <SignInPage />,
    public: true,
  },
  {
    path: appUrls.forgotpassword,
    name: "Forgot Password",
    component: <ForgotPasswordPage />,
    public: true,
  },
];
