import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import React, { createContext, useState } from "react";
import { useIsAuthenticated } from "./useIsAuthenticated";
import userPool from "./userPool";

export type AccountContextType = {
  authenticate: (email: string, password: string) => Promise<unknown>;
  getSession: () => Promise<unknown>;
  logOut: () => void;
  forgotPassword: (email: string) => void;
  confirmNewAccount: (email: string, code: string) => void;
  setUser: React.Dispatch<React.SetStateAction<CognitoUser | null>>;
  setNewPassword: (email: string, code: string, newPassword: string) => void;
  resendConfirmationCode: (email: string) => void;
};

const AccountContext = createContext<AccountContextType | null>(null);

interface IAccountProviderProps {
  children: React.ReactNode;
}

function AccountProvider({ children }: IAccountProviderProps) {
  const [user, setUser] = useState<CognitoUser | null>(
    userPool.getCurrentUser()
  );
  const { unAuthenticate } = useIsAuthenticated();

  const authenticate = async (email: string, password: string) => {
    return await new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: userPool,
      });

      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          setUser(userPool.getCurrentUser());
          resolve(data);
        },
        onFailure: (err) => {
          reject(err);
        },
        newPasswordRequired: (data) => {
          resolve(data);
        },
      });
    });
  };

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      try {
        const user = userPool.getCurrentUser();
        if (user) {
          user.getSession(
            async (err: Error | null, session: CognitoUserSession | null) => {
              if (err) {
                reject();
              } else {
                const attributes = await new Promise((resolve, reject) => {
                  user.getUserAttributes((err, attributes) => {
                    if (err) {
                      reject(err);
                    } else {
                      const results: Record<string, string> = {};
                      if (attributes) {
                        for (let attribute of attributes) {
                          const { Name, Value } = attribute;
                          results[Name] = Value;
                        }
                      }
                      resolve(results);
                    }
                  });
                });
                //@ts-ignore
                resolve({ user, ...session, ...attributes });
              }
            }
          );
        }
      } catch (e) {
        console.error(e);
      }
    });
  };

  const logOut = async () => {
    console.log("logOut");
    const user = userPool.getCurrentUser();
    console.log("user", user);
    if (user) {
      user.signOut(logOutCallback);
    }
  };

  const logOutCallback = () => {
    setUser(null);
    unAuthenticate();
  };

  const forgotPassword = async (email: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.forgotPassword({
      onSuccess: (data) => {
        console.log(data);
      },
      onFailure: (err) => {
        console.error(err);
      },
    });
  };

  const confirmNewAccount = async (email: string, code: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.confirmRegistration(code, false, (err, result) => {
      if (err) {
        console.error("registration WAS NOT successfully confirmed: ", err);
      } else {
        console.log("registration successfully confirmed: ", result);
      }
    });

    // using email to confirm registration also verifies attribute

    // user.verifyAttribute('email', code, {
    //     onSuccess: (success: string) => {
    //         console.log('email attribute verify success: ', success);
    //     },
    //     onFailure: (err: Error) => {
    //         console.error('email attribute verify FAILURE: ', err);
    //     }
    // });
  };

  const setNewPassword = async (
    email: string,
    code: string,
    newPassword: string
  ) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.confirmPassword(code, newPassword, {
      onSuccess() {
        console.log("password successfully confirmed");
      },
      onFailure() {
        console.error("password WAS NOT successfully confirmed");
      },
    });
  };

  const resendConfirmationCode = (email: string) => {
    const user = new CognitoUser({
      Username: email,
      Pool: userPool,
    });

    user.resendConfirmationCode((err, result) => {
      if (err) {
        console.error("resend confirmation code failed: ", err);
      } else {
        console.log("resend confirmation code success: ", result);
      }
    });
  };

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        getSession,
        logOut,
        forgotPassword,
        confirmNewAccount,
        setUser,
        setNewPassword,
        resendConfirmationCode,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
}

export { AccountProvider, AccountContext };
