import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { IntlProvider } from "react-intl";

import "./index.css";
import App from "./App";
import { AccountProvider } from "./auth/AccountProvider";
import messages from "./utils/intl/messages";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
// import { TouchBackend } from "react-dnd-touch-backend";

const locale = "en";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AccountProvider>
        <IntlProvider
          locale={locale}
          messages={messages[locale]}
          defaultLocale={locale}
        >
          <DndProvider backend={HTML5Backend}>
            <CssBaseline />
            <App />
          </DndProvider>
        </IntlProvider>
      </AccountProvider>
    </BrowserRouter>
  </React.StrictMode>
);
