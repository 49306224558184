import React, { useContext, useEffect, useState } from 'react';
import { AccountContext } from './AccountProvider';

function useIsAuthenticated() {
    const account = useContext(AccountContext);

    const [isAuthenticated, setIsAuthenticated] = useState(false);

    function unAuthenticate() {
        setIsAuthenticated(false);
    }

    useEffect(() => {
        if (account) {
            account.getSession()
                .then(() => {
                    setIsAuthenticated(true)
                })
                .catch((e) => {
                    setIsAuthenticated(false);
                });
        }
    }, [account]);

    return {isAuthenticated, unAuthenticate};
}

export { useIsAuthenticated };
