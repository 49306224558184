import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import "./App.css";
import { appRoutes, appUrls } from "./pages/routes";

function App() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        {appRoutes.map((r) => (
          <Route path={r.path} element={r.component} key={r.name} />
        ))}
        <Route path="*" element={<Navigate to={appUrls.signin} />} />
      </Routes>
    </Suspense>
  );
}

export default App;
